import { Attachment } from './attachment.model'
import { Customer } from './customer.model'
import { DesignParameter } from './design-parameter.model'
import { Invoice } from './invoice.model'
import { TicketProduct } from './product.model'
import { User } from './user.model'


export const ticketTypeOption = [
  {
    label: 'Product',
    value: 'product',
  },
  {
    label: 'Rush',
    value: 'rush',
  },
]

export const inMasterOptions = [
  {
    label: 'Included',
    value: 'true',
  },
  {
    label: 'Excluded',
    value: 'false',
  },
]

export class Ticket {
  id: number = 0
  createdAt: Date = new Date()
  updatedAt: Date = new Date()
  note?: string
  status?: string
  designer?: User
  qualityController?: User
  author?: Customer
  owner?: Customer
  designCollaborators: User[] = []
  qcCollaborators: User[] = []
  attachments: Attachment[] = []
  transitions: Transition[] = []
  totalPrice: number = 0
  totalDuration: number = 0
  dueDate: Date = new Date()
  rush: boolean = false
  invoice?: Invoice
  products: TicketProduct[] = []
  completedDate: Date = new Date()
  ticketNumber: string = ''
  designParameters: DesignParameter |  null = null
  constructor(data?: any) {
    if (data){
      this.id = data?.id
      this.createdAt = data?.createdAt
      this.updatedAt = data?.updatedAt
      this.note = data?.note
      this.status = data?.status
      this.designer = new User(data?.designer)
      this.qualityController = new User(data?.qualityController)
      this.author = new Customer(data?.author)
      this.owner = new Customer(data?.owner)
      this.totalPrice = data?.totalPrice
      this.totalDuration = data?.totalDuration
      this.dueDate = data?.dueDate
      this.rush = data?.rush
      this.completedDate = data?.completedDate
      this.ticketNumber = data?.ticketNumber
      this.designParameters = data?.designParameters
      if (data.invoice) {
        this.invoice = new Invoice(data?.invoice)
      }

      if (data?.designCollaborators) {
        this.designCollaborators = data.designCollaborators.map((d: any) => new User(d))
      }

      if (data?.qcCollaborators) {
        this.qcCollaborators = data.qcCollaborators.map((d: any) => new User(d))
      }

      if (data?.attachments) {
        this.attachments = data.attachments.map((d: any) => new Attachment(d))
      }

      this.transitions = []
      if (data?.transitions) {
        this.transitions = data.transitions.map((d: any) => new Transition(d))
      }

      if (data?.products) {
        this.products = data.products.map((p: any) => new TicketProduct(p))
      }
    }

  }

  isActive(): boolean {
    switch (this.status) {
      case 'Waiting For Validation':
      case 'Ready':
      case 'In Progress':
      case 'On Hold':
      case 'Waiting For QC':
        return true
    }

    return false
  }

  isCalculatingTime(): boolean {
    switch (this.status) {
      case 'Ready':
      case 'In Progress':
      case 'On Hold':
      case 'Waiting For QC':
        return true
    }

    return false
  }

  allowUpdateproducts(): boolean {
    switch (this.status) {
      case 'New':
      case 'Payment Pending':
      case 'Waiting For Validation':
      case 'Ready':
        return true
    }
    return false
  }

  getDuration = () => {
    const days = Math.trunc(this.totalDuration / 24 / 3600)
    const hours = Math.trunc(this.totalDuration / 3600) - 24 * days
    const minutes = Math.trunc((this.totalDuration % 3600) / 60)

    let result = ''

    if (days > 0){
      result = `${days} days`
    }

    if (hours > 0) {
      result = `${hours} hours`
    }
    
    if (minutes > 0) {
      result += ` ${minutes} minutes`
    }

    return result.trim()
  }

}

export class Transition {
  name: string
  to: string
  display?: string

  constructor(data?: any) {
    this.name = data?.name
    this.to = data?.to
  }
}

export class TicketPatch {
  note?: string
  designerId?: number
  qualityControllerId?: number
  designCollaborators?: number[]
  qcCollaborators?: number[]
}

export class TransitionTicketPayload extends Transition {
  note: string = ''
}

export const FieldMapping: any  = {
  id: 'ID',
  createdAt: 'Created At',
  note: 'Note',
  status: 'Status',
  designer: 'Designer',
  qualityController: 'Quality Controller',
  designCollaborators: 'Design Collaborators',
  qcCollaborators: 'QC Collaborators',
  attachments: 'Attachments',
  transitions: 'Transitions',
  products: 'Products',
  outputs: 'Outputs',
}

export const StateColorMapping: any = {
  New: 'magenta',
  'Payment Pending': 'red',
  'Waiting For Validation': 'volcano',
  Ready: 'orange',
  'In Progress': 'blue',
  'On Hold': 'cyan',
  'Waiting For QC': 'geekblue',
  Completed: 'purple',
  Closed: 'lime',
}

export class TicketProductPayload {
  productID: number
  quantity: number
  price: number

  constructor(productId: number, quantity: number, price: number) {
    this.productID = productId
    this.quantity = quantity
    this.price = price
  }
}
