import { HttpParams } from '@angular/common/http'

export class Product {
  id: number
  name: string
  type: ProductType
  duration: number
  price: number
  inMaster: boolean
  createdAt: Date
  updatedAt: Date
  deletedAt?: Date
  category: string = ''
  categoryId: number = 0
  categoryName: string = ''
  inCategory: boolean = false
  userId?: number
  productId?: number
  
  constructor(data?: any) {
    this.id = data?.id
    this.productId = data?.productId
    this.name = data?.name
    this.type = data?.type
    this.duration = data?.duration
    this.price = data?.price
    this.inMaster = data.inMaster
    this.createdAt = data?.createdAt
    this.updatedAt = data?.updatedAt
    this.deletedAt = data?.deletedAt
    this.category  = data?.category
    this.categoryId = data?.categoryId
    this.categoryName = data?.categoryName
    this.inCategory = data?.inCategory
  }

  getDuration = () => {
    const days = Math.trunc(this.duration / 24 / 3600)
    const hours = Math.trunc(this.duration / 3600) - 24 * days
    const minutes = Math.trunc((this.duration % 3600) / 60)

    let result = ''
    if (days > 1 ){
      result = `${days} days`
    } else if (days == 1){
      result = `${days} day`
    }

    if (hours > 0) {
      result = `${hours} hours`
    }
    
    if (minutes > 0) {
      result += ` ${minutes} minutes`
    }

    return result.trim()
  }
}

export enum ProductType {
  Product = 'product',
  Rush = 'rush',
}

export class TicketProduct {
  id: number
  ticketId: number
  product: Product
  price: number
  quantity: number

  constructor(data?: any) {
    this.id = data?.id
    this.ticketId = data?.ticketId
    this.product = new Product(data?.product)
    this.price = data?.price
    this.quantity = data?.quantity
  }
}

export class GetProductsOption {
  orderBy?: string
  orderType?: string
  page?: number
  perPage?: number
  status?: string
  type?: string
  inMaster?: string
  searchTerms?: string

  constructor(data: any) {
    this.orderBy = data.orderBy
    this.orderType = data.orderType
    this.page = data.page
    this.perPage = data.perPage
    this.status = data.status
    this.type = data.type
    this.inMaster = data.inMaster
    this.searchTerms = data.searchTerms
  }

  params(): HttpParams {
    let params = new HttpParams()
    if (this.orderBy) {
      params = params.set('orderBy', this.orderBy)
      params = params.set('isDesc', this.orderType == 'ascend' ? 'false' : 'true')
    }
    if (this.status) {
      params = params.set('status', this.status)
    }
    if (this.inMaster) {
      params = params.set('inMaster', this.inMaster)
    }
    if (this.searchTerms) {
      params = params.set('searchTerms', this.searchTerms)
    }
    if (this.type) {
      params = params.set('type', this.type)
    }
    return params
  }
}
