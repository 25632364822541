import {
  Directive,
  ElementRef,
  Renderer2,
  Input,
  HostListener,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core'

@Directive({
  selector: '[autosuggest]',
})
export class AutoSuggestDirective implements OnChanges {
  @Input() open: boolean = false
  @Output() setOpen = new EventEmitter<boolean>()
  constructor(private elmRef: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(change: any): void {
    if (this.open) {
      this.renderer.setStyle(this.elmRef.nativeElement, 'display', 'block')
      this.renderer.setStyle(this.elmRef.nativeElement, 'border-radius', '8px')
      this.renderer.setStyle(this.elmRef.nativeElement, 'position', 'absolute')
      this.renderer.setStyle(this.elmRef.nativeElement, 'width', '100%')
      this.renderer.setStyle(this.elmRef.nativeElement, 'max-height', '212px')
      this.renderer.setStyle(this.elmRef.nativeElement, 'top', '34px')
      this.renderer.setStyle(this.elmRef.nativeElement, 'background', '#fff')
      this.renderer.setStyle(this.elmRef.nativeElement, 'overflow-y', 'auto')
      this.renderer.setStyle(this.elmRef.nativeElement, 'z-index', '39')
      this.renderer.setStyle(this.elmRef.nativeElement, 'padding', '8px 0')
      this.renderer.setStyle(this.elmRef.nativeElement, 'border', '1px solid #165EDA')
    } else {
      this.renderer.setStyle(this.elmRef.nativeElement, 'display', 'none')
    }
  }

  // @HostListener('document:click', ['$event'])
  // docEvent($e: MouseEvent) {
  //   if (!this.open) {
  //     return
  //   }
  //   const paths: Array<HTMLElement> = $e['path']
  //   if (!paths.some(p => p === this.elmRef.nativeElement)) {
  //     this.setOpen.emit(false)
  //   }
  // }
}
