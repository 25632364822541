import { User } from './user.model'

export class Customer extends User {
  address: string
  clinicName: string
  phone: string
  website: string
  isLab: boolean

  constructor(data?: any) {
    super(data)

    this.address = data?.address || ''
    this.clinicName = data?.clinicName || ''
    this.phone = data?.phone || ''
    this.website = data?.website || ''
    this.isLab = data?.role == 'lab'
  }
}
