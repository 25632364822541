import { Pipe, PipeTransform } from '@angular/core'
import { BackofficeRoleOptions } from '../models/user.model'



@Pipe({ name: 'roleMapping' })
export class RoleMappingPipe implements PipeTransform {
  transform(value: string): string {
    const dictionary = Object.assign({}, ...BackofficeRoleOptions.map(x => ({ [x.name]: x.value })))
    return dictionary[value]
  }
}
