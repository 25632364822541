import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

@Injectable({ providedIn: 'root' })
export class CommonApi {
  constructor(private http: HttpClient) {}

  uploadImg(formData: FormData) {
    return this.http.post('/upload', formData).toPromise() as any
  }
}
