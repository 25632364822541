import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'productDuration',
  pure: true,
})
export class ProductDurantionPipe implements PipeTransform {
  
  transform(value: any): any {
    if (value) {
      const days = Math.trunc(value / (24 * 3600))
      const hours = Math.trunc(value/ 3600) - 24 * days
      const minutes = Math.trunc((value % 3600) / 60)

      let result = ''
      if (days > 1 ){
        result += `${days} days `
      } else if (days == 1){
        result += `${days} day `
      }

      if (hours > 0) {
        result += `${hours} hours`
      }
      
      if (minutes > 0) {
        result += ` ${minutes} minutes`
      }

      return result.trim()
    }
    return 0
  }
}
