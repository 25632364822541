export enum StorageKeys {
  AccessToken = "accessToken",
  CurrentUser = "currentUser",
  Remember = "remember"
}

export enum BackOfficeUserRole {
  SuperAdmin = "bo.super_admin",
  Admin = "bo.admin",
  CustomerSupport = "bo.customer_support",
  CADDesigner = "bo.cad_designer",
  Accountant = "bo.accountant",
  QC = "bo.qc"
}

export const BackofficeRoleOptions = [
  { name: BackOfficeUserRole.SuperAdmin, value: "Super Admin" },
  { name: BackOfficeUserRole.Admin, value: "Admin" },
  // { name: BackOfficeUserRole.CustomerSupport, value: 'Customer Support' },
  { name: BackOfficeUserRole.CADDesigner, value: "CAD Designer" },
  { name: BackOfficeUserRole.Accountant, value: "Accountant" },
  { name: BackOfficeUserRole.QC, value: "QC" }
];

export enum BackOfficeUserStatus {
  Active = "active",
  Inactive = "inactive"
}

export const BackofficeUserStatusOptions = [
  { name: BackOfficeUserStatus.Active, value: "Active" },
  { name: BackOfficeUserStatus.Inactive, value: "Inactive" }
];

export class User {
  id: number = 0;
  createdAt?: Date;
  updatedAt?: Date;
  email: string = '';
  name: string = '';
  role?: BackOfficeUserRole;
  status: string = '';
  profileImageUrl: string = '';
  stats?: UserStats;
  checked?: boolean;
  isOnline: boolean = false ;
  lastActive?: Date;

  constructor(data?: any) {
    if (!data) {
      return;
    }

    this.id = data.id;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.email = data.email;
    this.name = data.name;
    this.role = data.role;
    this.status = data.status;
    this.profileImageUrl = data.profileImageUrl;
    this.isOnline = data?.isOnline;
    this.lastActive = data?.lastActive;

    if (data.stats) {
      this.stats = new UserStats(data.stats);
    }
  }

  isAdmin(): boolean {
    return (
      this.role === BackOfficeUserRole.Admin ||
      this.role === BackOfficeUserRole.SuperAdmin
    );
  }

  canViewPrice(): boolean {
    return (
      this.role === BackOfficeUserRole.Admin ||
      this.role === BackOfficeUserRole.SuperAdmin ||
      this.role === BackOfficeUserRole.Accountant
    );
  }
}

export class CreateUserPayload {
  email: string = '';
  name: string = '';
  role: string = '';
  status: string = '';
  password: string = '';
}

export class ChangePasswordPayload {
  currentPassword?: string;
  newPassword: string = '';
}

export class LoginPayload {
  email: string = '';
  password: string = '';
}

export class UserStats {
  doing: number;
  onTime: number;
  overdue: number;
  total: number;

  constructor(data?: any) {
    this.doing = data?.doing;
    this.onTime = data?.onTime;
    this.overdue = data?.overdue;
    this.total = data?.total;
  }
}
