import { Pipe, PipeTransform } from '@angular/core'
import { UtilService } from '../services/util.service'


@Pipe({ name: 'timeRemaining' })
export class TimeRemainingPipe implements PipeTransform {

  constructor(private utilService: UtilService) {}

  transform(value: any): string {
    if (!value){
      return ''
    }
    const seconds = Math.floor((+new Date(value) - +new Date()) / 1000)
    if (seconds < 0) {
      return 'Overdue'
    }

    return this.utilService.durationStringFromSeconds(seconds)
  }

}
