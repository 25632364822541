import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'dateAgo',
  pure: true,
})
export class DateAgoPipe implements PipeTransform {
  readonly intervals: any = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  }

  readonly shortIntervals: any = {
    hour: 'h',
    minute: 'm',
    second: 's',
  }

  extract(seconds: number) {
    let counter: any 
    for (const i in this.intervals) {
      counter = Math.floor(seconds / this.intervals[i])
      if (counter > 0)
        if (counter === 1) {
          return counter + ' ' + i + ' ago'
        } else {
          return counter + ' ' + i + 's ago'
        }
    }

    return 0
  }

  extractShort(seconds: number) {
    if (seconds > this.intervals.hour) {
      return ''
    }

    let counter = 0
    for (const i in this.intervals) {
      counter = Math.floor(seconds / this.intervals[i])
      if (counter > 0) {
        return counter + this.shortIntervals[i]
      }
    }
  }

  transform(value: any, size: string): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000)      
      if (seconds < 29) {
        // less than 30 seconds ago will show as 'Just now'
        return 'Just now'
      }

      if (size == 'short') {
        return this.extractShort(seconds)
      }

      return this.extract(seconds)
    }

    return value
  }
}
