export class Invoice {
  id: number
  userId: number
  orderId: string
  createdAt: Date
  status: string
  captureId?: string
  numOfTickets?: number
  userName?: string
  userClinicName?: string
  userEmail?: string
  price: number
  discount: number

  constructor(data?: any) {
    this.id = data?.id
    this.userId = data?.userId
    this.orderId = data?.orderId
    this.createdAt = data?.createdAt
    this.status = data?.status
    this.captureId = data?.captureId
    this.numOfTickets = data?.numOfTickets || 0
    this.userName = data?.userName
    this.userClinicName = data?.userClinicName || ''
    this.userEmail = data?.userEmail
    this.price = data?.price || 0
    this.discount = data?.discount || 0
  }
}

export interface InvoiceListingOption {
  userId?: number
  status?: string
  from?: number
  to?: number
  page: number
  perPage: number
}